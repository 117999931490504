
.porfolio-container {
    display: flex;
    flex-wrap: wrap;
    &> div {
    flex: 40%; 
    margin: 20px;
    }

    .card-img {
        border-radius: 0.25rem;
    }

    .card-text:last-child {
        position: absolute;
        bottom: 0;
        span {
            font-size: 11px;
            margin-right: 6px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .porfolio-container {
        &> div {
        flex: 100%; 
        margin: 10px;
        }
    }
}
.portfolio-body-card {
    display: flex;
    border: 2px solid $pumpkin-skin;
    border-radius: 15px;
    .card-portfolio-image {
        
        img {
            border-radius: 15px;
            max-width: 100%;
            width: 300px;
        }
    }

    .card-portfolio-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .card-title {
            font-size: 18px;
            text-align: center;
            font-weight: 500;
            color: #000;
            line-height: 20px;
            padding: 10px;
        }

        .portfolio-description {
            margin: 0;
            color: rgb(102, 102, 102);
            span {
            margin-left: 8px;
            color: #c4c4c4;
            }
            i {
            border-radius: 50%;
            font-size: 6px;
            background-color: #c4c4c4;
            margin-right: 3px;

            }
            }
    }
}


.portfolio-button {
    display: flex;
    justify-content: flex-end;
    .btn-portfolio {
        background-color: #e1d8c7;
        border: 1px solid transparent;
        color: #000000;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        
        i {
            margin-right: 6px;
            font-size: 16px;
        }
    }
}
