// 
// projects.scss
//

// project list
.projects-container {
  display: flex;
  flex-wrap: wrap;
  &> div {
    margin: 10px;
    }
}


.project-list-table{
    border-collapse:separate; 
    border-spacing:0 12px; 
  tr{
    background-color: $card-bg;
  }
}

.project-card {
  border: 2px solid #000;
  border-radius: 20px;


  .project-title {
      font-size: 18px;
      color: #000;
  }
  .project-description {
      color: #000;
  }
  .project-date {
      color: rgb(83, 83, 83);
  }
}

.buttons-project {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  &>a {
      margin: 18px;
  }
}

.buttons-project-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  &>a {
      margin: 18px;
  }
}


.btn-project {
  background-color: #998e7a;
  border: 1px solid #000000;
  color: #000000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  span {
    font-size: 1.5rem;
  }
  
}


@media only screen and (max-width: 768px) {
  .btn-project {
      width: 100%;
  }
}


.topic-list {
text-decoration: none;
list-style-type: square;
}
.topic-item {
  text-transform: capitalize;
}


.content-cards {
  display: flex;
  flex-wrap: wrap;
}

.content-container  {
  &>div {
    margin: 6px;
  }
}

.content-cards {
  &>div {
    margin: 6px;
  }
}

.editor-features {
  &>button {
    margin: 6px;
  }
}


.content-card-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* &> a {
    margin: 6px;
    } */
}

.content-card-link__idea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
}


.project-pagination {
  display: flex;
  justify-content: center;
}

.project-delete-confirmation {
  .icon-box{
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .modal-content * {
    border: none;
  }
  // created by reactstrap ModalHeader
  .modal-title, .modal-footer {
    margin: 0 auto;
  }
  .modal-body {
    padding-top: 0px;
  }
  
  .modal-header .close {
      position: absolute;
      top: -2px;
      right: 2px;
    }
  
  .red-circle{
    width: 76px;
    height: 76px;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    vertical-align: middle;
    padding: 5px;
    font-size: 60px;
    color:red;
    transition:0.2s;
    background-color: white;
    border: 3px solid red;
  }
}


// Project Selection Cards Styles
div.radio-with-Icon {
  display: block;
}
div.radio-with-Icon p.radioOption-Item {
  display: inline-block;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  margin: 25px 15px;
  border: none;
}
div.radio-with-Icon p.radioOption-Item label {
  display: block;
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #998e7a;
  color: #998e7a;
  cursor: pointer;
  opacity: .8;
  transition: none;
  font-size: 13px;
  padding-top: 25px;
  text-align: center;
  margin: 0 !important;
}
div.radio-with-Icon p.radioOption-Item label:hover, div.radio-with-Icon p.radioOption-Item label:focus, div.radio-with-Icon p.radioOption-Item label:active {
  opacity: .5;
  background-color: #998e7a;
  color: #fff;
  margin: 0 !important;
}
div.radio-with-Icon p.radioOption-Item label::after, div.radio-with-Icon p.radioOption-Item label:after, div.radio-with-Icon p.radioOption-Item label::before, div.radio-with-Icon p.radioOption-Item label:before {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
}
div.radio-with-Icon p.radioOption-Item label i.fa {
  display: block;
  font-size: 50px;
}
div.radio-with-Icon p.radioOption-Item input[type="radio"] {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
}
div.radio-with-Icon p.radioOption-Item input[type="radio"]:active ~ label {
  opacity: 1;
}
div.radio-with-Icon p.radioOption-Item input[type="radio"]:checked ~ label {
  opacity: 1;
  border: none;
  background-color: #998e7a;
  color: #fff;
}
div.radio-with-Icon p.radioOption-Item input[type="radio"]:hover, div.radio-with-Icon p.radioOption-Item input[type="radio"]:focus, div.radio-with-Icon p.radioOption-Item input[type="radio"]:active {
  margin: 0 !important;
}
div.radio-with-Icon p.radioOption-Item input[type="radio"] + label:before, div.radio-with-Icon p.radioOption-Item input[type="radio"] + label:after {
  margin: 0 !important;
}

// Active

div.radio-with-Icon p.radioOption-Item .active{
  opacity: 1;
  border: none;
  background-color: #998e7a;
  color: #fff;
}

// collapse
.collapse-charts {
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-hover {
    transition: opacity .3s, transform .3s;
    transform: rotate(-180deg);
}

.arrow-collapse {
  cursor: pointer;
}
}

.emoji {
  font-size: 48px;
}


.content-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  &>div {
    margin: 8px;
  }
}

