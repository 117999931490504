// 
// _progress.scss
// 


.progress-loading-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

// Progress height small
.progress-sm {
  height: 5px;
}


// Progress height medium
.progress-md {
  height: 8px;
}


// Progress height large
.progress-lg {
  height: 12px;
}

// Progress height Extra large
.progress-xl {
  height: 16px;
}

.custom-progess{
  position: relative;
  .progress-icon{
      position: absolute;
      top: -12px;
      .avatar-title{
        background: $card-bg;
      }
  }
}

.custom-progess-readability{
  position: relative;
  .progress-icon{
    position: absolute;
    top: -12px;
    .avatar-title{
      background: $pumpkin-skin;
    }
  }
}

.custom-progess-headline{
  position: relative;
  .progress-icon{
    position: absolute;
    top: -12px;
    .avatar-title{
      background: $yellow-sea;
    }
  }
}

.custom-progess-lexical-richness{
  position: relative;
  .progress-icon{
    position: absolute;
    top: -12px;
    .avatar-title{
      background: $green;
    }
  }
}

.custom-progess-lexical-fakeness{
  position: relative;
  .progress-icon{
    position: absolute;
    top: -12px;
    .avatar-title{
      background: $purple;
    }
  }
}

.custom-progess-political-bias{
  position: relative;
  .progress-icon{
    position: absolute;
    top: -12px;
    .avatar-title{
      background: $pink;
    }
  }
}

.custom-progess-cognitive-bias{
  position: relative;
  .progress-icon{
    position: absolute;
    top: -12px;
    .avatar-title{
      background: $cyan;
    }
  }
}

.custom-progess-subjective-bias{
  position: relative;
  .progress-icon{
    position: absolute;
    top: -12px;
    .avatar-title{
      background: $green;
    }
  }
}

.custom-progess-gender-bias{
  position: relative;
  .progress-icon{
    position: absolute;
    top: -12px;
    .avatar-title{
      background: $orange;
    }
  }
}

.custom-progess-emotion{
  position: relative;
  .progress-icon{
    position: absolute;
    top: -12px;
    .avatar-title{
      background: $red;
    }
  }
}

.custom-progess-bias{
  position: relative;
  .progress-icon{
    position: absolute;
    top: -12px;
    .avatar-title{
      background: $totem-pole;
    }
  }
}

// animated-progess

.animated-progess{
  position: relative;
  .progress-bar {
    position: relative;
    border-radius: 6px;
    animation: animate-positive 2s;
  }
}

@keyframes animate-positive {
  0% {
      width: 0;
  }
}

.progress {
  display: flex;
  height: 0.925rem;
  overflow: hidden;
  font-size: 0.55938rem;
  background-color: #eeeeee;
  border-radius: 0.25rem;
  width: 80px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
  padding: 3px;
}