.earse-score-container {
    .earse-score-container-title {
        font-style: italic;
        font-size: 15px;
        text-align: left;
        margin-top: 15px;
    }
    .earse-score-container-text {
        font-style: italic;
        font-size: 15px;
        text-align: left;
        margin-top: 18px;
    }
    table thead{
        tr  {
            background-color: #E0E0E0;
            th {
                background-color: #E0E0E0;
                font-size: 14px !important;
            }
        }
    }
    tbody tr td {
        font-size: 14px !important;
    }
}