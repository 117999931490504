.tabs {
    display: flex;
    &>div {
        margin: 6px;
    }
  }
  
 /*  .tabitem {
    cursor: pointer;
    width: 120px;
    height: 25px;
    text-align: center;
    padding: 15px;
    background-color: transparent;
    border-radius: 20px;
    border: 2px solid #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
  } */

  .tabitem {
    cursor: pointer;
    height: 25px;
    text-align: center;
    padding: 15px;
    background-color: transparent;
    border-radius: 20px;
    border: 2px solid #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

  
  .tabitem--inactive {
    opacity: 0.3;
    filter: grayscale(100%);
  }
  
  
  .tabitem__title {
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
    margin: 10px 0;
  }
  
  .content {
    text-align: center; 
    margin-top: 15px;
    font-size: 22px;
  }


  .tabs-main {
    display: flex;
    &>div {
        margin: 6px;
    }
  }
  
  .tab-main-item {
    cursor: pointer;
    width: 120px;
    height: 25px;
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tab-item-main--inactive {
    color: rgba(0, 0, 0, 0.9);
    opacity: 0.3;
    filter: grayscale(100%);
  }
  
  
  .tab-item-main__title {
    font-weight: 600;
    color: #cb0b96;
    font-size: 15px;
    margin: 10px 0;
  }