// 
// _card.scss
// 

/* .card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
} */

.custom-card {
  .card {
    position: relative;
    margin: .5rem 0 1rem 0;
    background-color: #fff;
    -webkit-transition: -webkit-box-shadow .25s;
    transition: -webkit-box-shadow .25s;
    transition: box-shadow .25s;
    transition: box-shadow .25s, -webkit-box-shadow .25s;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    border: 2px solid #000;
    margin-left: 5px;
  }
}

.card-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  
}

.card-image img {
  border-radius: 15px;
  width: 100%;
  height: 80%;
  padding: 5px;
}

img {
  border-style: none;
}


.card-content {
  padding: 24px;
  border-radius: 0 0 2px 2px;
  width: 66.66%;
}
.card-description {
margin: 0;
position: absolute;
bottom: 0;
font-size: 10px;
color: rgb(102, 102, 102);

span {
  margin-left: 8px;
  color: #c4c4c4;
}
i {
  border-radius: 50%;
  font-size: 6px;
  background-color: #c4c4c4;
  margin-right: 3px;

}
}

.card-title {
  //display: block;
  line-height: 15px;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 300;
}

.btn-floating.btn-large.halfway-fab {
  bottom: -28px;
}

.btn-floating.halfway-fab {
  position: absolute;
  right: 24px;
  bottom: -20px;
}

.btn-floating.btn-large {
  width: 56px;
  height: 56px;
  padding: 0;
}

.btn-large {
  height: 54px;
  line-height: 54px;
  font-size: 15px;
  padding: 0 28px;
}

.btn-floating {
  display: inline-block;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  background-color: #26a69a;
  border-radius: 50%;
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  cursor: pointer;
  vertical-align: middle;
}


.card-portfolio {
  display: flex;
  justify-content: center;
  align-items: center;
  &>div {
    margin: 8px;
  }
}


.dashboard-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.dashboard-body-card {
  margin: auto;
}
