
.ideas-container {
    display: flex;
    flex-wrap: wrap;
    &> div {
    flex: 40%; 
    margin: 20px;
    }
}
.idea-card {
    border: 2px solid #000;
    border-radius: 20px;


    .card-title {
        font-size: 18px;
        color: #000;
    }
    .idea-description {
        color: #000;
    }
    .idea-date {
        color: rgb(83, 83, 83);
    }
}

.idea-button {
    display: flex;
    justify-content: flex-end;
    .btn-idea {
        background-color: #e1d8c7;
        border: 1px solid transparent;
        color: #000000;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        
        i {
            margin-right: 6px;
            font-size: 16px;
        }
    }
}


// Screenplay
.main-screenplay-menu {
    display: flex;
    &>div {
        margin: 8px;
    }
}
.screenplay-container {
    display: flex;
    width: calc(100% - 250px);;
    height: 100%;
    margin-top: 20px;
}

.section-left {
    background-color: transparent;
    overflow: hidden;
}

.section-right {
    background-color: transparent;
    overflow: hidden;
}

.divider {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: #9E9E9E;
    cursor: col-resize;
}


//editor

.screenplay-editor {
    display: flex;
    flex-direction: row;

    .screenplay-textarea {
        flex: 1;
        padding: 10px;
        font-size: 16px;
        font-family: monospace;
        border: 1px solid #ccc;
    }

    .screenplay-preview {
        flex: 1;
        padding: 10px;
        font-size: 16px;
        font-family: serif;
        overflow-y: scroll;
        overflow-x: hidden;
        height: calc(100vh - 500px);
    }
}


// Accordion
.accordion-container {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .accordion-header {
    background-color: #f2f2f2;
    padding: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  
  .accordion-heading {
    font-size: 18px;
    font-weight: bold;
  }
  
  .accordion-arrow {
    font-size: 18px;
    margin-left: 10px;
  }
  
  .accordion-arrow.up {
    transform: rotate(180deg);
  }
  
  .accordion-body {
    padding: 18px;
  }

  .scene-heading-container {
    border: 1px solid #ccc;
    background-color: #FFFFFF;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .act-heading {
    border: 1px solid #ccc;
    background-color: #0000fd;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .scene-heading-container div {
    font-size: 20px;
    font-weight: bold;
  }

  .scene-heading-inputs {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.scene-heading-inputs input {
    border: 1px solid #ccc;
    background-color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    color: #333;
}

.scene-heading-inputs input:focus {
    outline: none;
}

.context-menu {
    position: absolute; /* position the menu relative to the window */
    background-color: white;
    border: 1px solid gray;
    padding: 10px;
    z-index: 1; /* make sure the menu appears above other elements on the page */
  }
  
  .context-menu-item {
    cursor: pointer; /* change the cursor to a pointer when hovering over the menu items */
    padding: 5px;
  }
  
  .context-menu-item:hover {
    background-color: #f2f2f2; /* change the background color when hovering over the menu items */
  }

  // Gantt
  .gantt-chart {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
  }
  
  .task {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  
  .task-name {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .task-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #4CAF50;
  }
  
  .task-details {
    margin-top: 10px;
  }
  

  .screenplay-lane {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
  
  .screenplay-header-a,
  .screenplay-body-a,
  .screenplay-nav-a,
  .screenplay-header-b,
  .screenplay-body-b,
  .screenplay-nav-b {
    grid-column: 1 / -1;
    }
    
    .screenplay-header-a {
    background-color: #A9A9A9;
    }
    
    .screenplay-header-b {
    background-color: #ADD8E6;
    }

    .tag-modal div {
        margin-bottom: 12px;
    }

    .circle-picker {
        width: 100% !important;
    }


    .script-editor-tags {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
      }
      
      .tag {
        padding: 5px;
        color: white;
        margin: 5px;
        border-radius: 5px;
        font-size: 14px;
        white-space: nowrap;
      }
      
      .remove-tag-button {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 12px;
        margin-left: 5px;
        cursor: pointer;
      }
      
      .remove-tag-button:hover {
        color: red;
      }
      
      .main-lane {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 900;
      }


     /*  .acts-timeline {
        .timeline {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          
          .timeline::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            bottom: 0;
            border-left: 2px solid #ccc;
          }
          
          .timeline > div {
            position: relative;
            margin: 20px 0;
            width: 100%;
          }
          
          .timeline > div::before {
            content: "";
            position: absolute;
            left: -20px;
            top: 0;
            bottom: 0;
            border-left: 2px solid #ccc;
          }
          
          .timeline > div h2 {
            font-size: 1.2em;
            margin-top: 0;
          }
          
          .timeline > div p {
            font-size: .9em;
            margin: 5px 0;
          }
          
          
      } */

/* Parent container for the timeline */
.timeline {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: scroll;
    position: relative;
  }
  
  /* Pseudo-element for the vertical line in the center of the timeline */
  .timeline::before {
    content: "";
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: #ccc;
    z-index: 1;
  }
  
  /* Parent container for the acts */
  /* .act {
    width: 80%;
    height: auto;
    margin: 0 10%;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #ccc;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    position: relative;
  } */
  
  /* Pseudo-element for the small horizontal line on the left side of each act */
  /* .act::before {
    content: "";
    position: absolute;
    left: -20px;
    width: 20px;
    height: 100%;
    background-color: #ccc;
    z-index: 1;
  } */
  
  // scene
.scene {
    width: 100%;
    min-height: 30px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #ccc;
  }



  .screenplay {
    display: flex;
    flex-direction: column;
    //align-items: center;
    width: 100%;
    //margin: 0 auto;
    padding: 2em;
  }
  
  .main {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 2em;
  }
  
  .acts {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 8px;
    width: 100%;
    padding: 1em;
    border: 1px solid black;
    //border-radius: 10px;
    //background-color: white;
  }

  .act-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1em;
    border: 1px solid black;
    margin-bottom: 30px;
    position: relative;
    .button-scene {
      position: absolute;
      bottom: 0;
      right: 0;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
  }
  
  .act-empty p {
    margin-bottom: 0;
    padding: 12px;
    font-size: 16px;
  }

  .act {
    width: 100%;
    text-align: center;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 10px;
    margin-right: 8px;
    h2 {
      font-size: 16px;
    }
  }
  .act.active {
    background-color: #e6e6e6;
  }
  .act + .act {
    margin-left: 8px;
  }
  
  .scenes {
    width: 80%;
    margin: 20px 0;
    padding: 20px;
    border: 1px solid lightgray;
  }
  
  
  
  


  .screenplay-act-container {
    width: 100%;
    background: #FFF;
    padding: 10px;
    margin: 0 auto;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .screenplay-header {
    margin-bottom: 30px;
  }
  
  .screenplay-header h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .screenplay-header p {
    font-size: 18px;
    font-style: italic;
    color: #777;
  }
  
  .acts {
    margin-bottom: 30px;
  }
  .act-header {
    background-color: #FFFFFF;
    display: inline-flex;
    padding: 10px;
    border: 1px solid black;
    border-bottom: none;
    float: left;
    position: relative;

    &>div {
      margin: 6px;
    }

    h2 {
      margin: 0;
      font-size: 16px;
    }
  }
  .acts h2 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
 
  .scene {
    background-color: #f2f2f2;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  
  .scene h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .scene p {
    font-size: 14px;
    color: #777;
  }
  

  .delete-button {
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
  }




// scene.scss

.act-screenplay {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .act-header-screenplay {
    width: 100%;
    height: 50px;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;

    .act-header-label {
      font-size: 18px;
      font-weight: bold;
    }

    .remove-icon {
      position: absolute;
      left: 10px;
      cursor: pointer;
    }

    .add-icon {
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }

  .scenes-container-screenplay {
    width: 100%;
    display: flex;
    justify-content: center;

    .scene-screenplay {
      width: 200px;
      height: 100px;
      margin: 10px;
      border: 1px solid black;
      position: relative;

      &::before {
        content: '';
        border-left: 1px solid black;
        border-right: 1px solid black;
        height: 50px;
        position: absolute;
        top: 25px;
        left: -10px;
        right: -10px;
      }

      .scene-header-screenplay {
        width: 100%;
        height: 50px;
        background-color: lightgray;
        display: flex;
        justify-content: center;
        align-items: center;

        .scene-header-label {
          font-size: 16px;
          font-weight: bold;
        }

        .remove-icon {
          position: absolute;
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }
}




//style for the storyboard:

.storyboard {
  display: flex;
  //flex-direction: column;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  &>div {
    margin: 8px;
  }

  &__add-act {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    &:hover {
      color: orange;
    }
  }

  &__add-scene {
    cursor: pointer;

    &:hover {
      color: white;
    }
  }

  &__act {
    width: auto;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    background: #FFF;
    border: 2px solid #000;

    &-header {
      width: 100%;
      height: 40px;
      background-color: orange;
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;

      &-remove {
        cursor: pointer;

        &:hover {
          color: white;
        }
      }

      &-title {
        font-size: 20px;
        font-weight: bold;
      }

      
    }

  }

  &__scenes {
    display: flex;
    margin: 20px;
    &-empty {
    display: flex;
    margin: 48px;
    }
    &-headline {
      font-size: 20px;
    }
  }  

  &__scene:first-child {
    margin-left: 20px;
  }
  &__scene:last-child {
    margin-right: 20px;
  } 
  &__scene {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    
    &-component:last-child {
      width: 200px;
    }
    &-component {
      display: flex;
      flex-direction: column;
      background-color: #FFFFFF;
      padding: 0px;
      margin: 0px;
      border-radius: 0px;
      border: 1px solid #000000;
      width: 300px;
      max-width: 100%;

      &-header {
        width: 100%;
        height: 40px;
        background-color: orange;
        color: white;
        display: flex;
        align-items: center;
        padding: 0 10px;
  
        &-title {
          margin: 0 auto;
          font-size: 15px;
          font-weight: bold;
        }

        &-remove {
          cursor: pointer;
  
          &:hover {
            color: white;
          }
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        padding: 10px;
        width: 100%;
        text-align: center;
        &-description {
          font-size: 14px;
          font-weight: normal;
        }
      }
      
    }

   

    &-connector {
      width: 100%;
      height: 2px;
      background-color: #000;
      margin: 0px;
    }
  }
}


.screenplay-list {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
}

.screenplay-list th,
.screenplay-list td {
  padding: 1rem;
  border: 1px solid #ddd;
}

.screenplay-list th {
  background-color: #ddd;
  font-weight: bold;
}

.screenplay-list tr:nth-child(even) {
  background-color: #f2f2f2;
}




// Read More button

.read-more-button {
  //background-color: #007bff;
  color: #007bff;
  font-weight: normal;
  //border: none;
  //border-radius: 4px;
  //padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
}


.screenplay-info {
  display: flex;
  //margin-bottom: 
  &>input {
    margin: 8px;
  }
}