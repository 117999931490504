.tags-input-container{
    //border: 2px solid #000;
    //padding: .5em;
    //border-radius: 3px;
    //width: min(80px, 600px);
    width: 100%;
    margin-top: 1em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;

    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.tag-item{
    background-color: rgb(218, 216, 216);
    display: inline-block;
    padding: .5em .75em;
    border-radius: 20px;
}
.tag-item .close{
    height: 20px;
    width: 20px;
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 18px;
    cursor: pointer;
}

.tags-input{
    flex-grow: 1;
    padding: .5em 0;
    border: none;
    outline: none;
}

.close-tag {
    cursor: pointer;
}