.action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    &>a {
        margin: 3px;
    }
    .edit {
        color: rgb(31, 129, 31);
        cursor: pointer;
    }
    .delete {
        color: rgb(233, 4, 4);
    }
}

@media  (min-width: 768px) {
    .table-responsive {
        overflow-x: hidden;
    }
}

.table {
	border-spacing: 0 15px;
	border-collapse: separate;
}
.table thead tr th,
.table thead tr td,
.table tbody tr th,
.table tbody tr td {
	vertical-align: middle;
	border: none;
}
.table thead tr th:nth-last-child(1),
.table thead tr td:nth-last-child(1),
.table tbody tr th:nth-last-child(1),
.table tbody tr td:nth-last-child(1) {
	text-align: center;
}
.table tbody tr {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
}
.table tbody tr td {
	background: #fff;
}
.table tbody tr td:nth-child(1) {
	border-radius: 5px 0 0 5px;
}
.table tbody tr td:nth-last-child(1) {
	border-radius: 0 5px 5px 0;
}
