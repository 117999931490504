$blue: #556ee6;
$white: #FFFFFF;
$yellow: #f1b44c;
$yellow-sea: #fbb304;
$totem-pole: #94240c;
$pumpkin-skin: #c4600c;

.readon {
    position: relative;
    display: inline-block !important;
    background: $blue;
    padding: 14px 30px;
    line-height: normal;
    color: #ffffff !important;
    transition: all 0.3s ease 0s;
    border-radius: 30px;
    text-transform: capitalize !important;
    cursor: pointer;
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
}
.readon:hover,
.readon:focus {
    background: #242526;
}

.inner{
    width:100%;
    float:left;
    position:relative;
}



.pricingTable .holder{
    background: #fff;
    box-shadow: 1px 20px 12px -15px rgba(0,0,0,0.2);
    padding: 30px 15px;
    text-align: center;
    border: 1px solid rgba(0,0,0,0.05);
    transition:0.5s ease;
}

.pricingTable .holder:hover{
    transform:translateY(-5px);
    
}



.pricingTable .holder .hdng p{
    font-size:28px;
    font-weight:bold;
    color:#242526;
}

.pricingTable .holder .img img{
    width:70%;
}

.pricingTable .holder .price p{
    color:$pumpkin-skin;
    margin-bottom:25px;    
}

.pricingTable .holder .price p b{
    font-size:40px;
    font-weight:bold;
}

.pricingTable .holder .price p span{
    font-size:18px;
}

.pricingTable .holder .info p{
    margin-bottom:15px;
    color:#242526;
    font-weight:14px;
}

.pricingTable .holder.active{
    background:$pumpkin-skin;
}

.pricingTable .holder.active .hdng p,
.pricingTable .holder.active .price p,
.pricingTable .holder.active .info p{
    color:#fff;
}

.pricingTable .holder.active .readon{
    background:#fff;
    color:$pumpkin-skin!important;
}

.pricingTable .holder.active .readon:hover{
    background:#242526;
    color:#fff!important;
}

.pricingTable .tabsBtnHolder ul{
    float:left;
    display:block;
    width:100%;
    max-width:326px;   
    border-radius:1.6666666667rem;
    margin:0px auto;
    margin-bottom:40px; 
    background:$pumpkin-skin;
    text-align:center;
    position:relative;
}

.pricingTable .tabsBtnHolder ul li{
    float:left;
    width:calc(100% / 2);
    display:inline-block;
    transition:0.4s ease;
    height: 45px;
}

.pricingTable .tabsBtnHolder ul li p{
    color:#fff;
    padding:10px 15px;
    z-index:10;
    position:relative;
    cursor:pointer;
}

.pricingTable .tabsBtnHolder ul li p.active{
    color:$pumpkin-skin;
}

.pricingTable .tabsBtnHolder ul li p {
    display: flex;
    align-items: center;
    justify-content: center;
}
.pricingTable .tabsBtnHolder ul li.indicator{
    position: absolute;
    top: 50%;
    left: 2px; /*163px*/
    background: #fff;
    height: calc(100% - 4px);
    transform: translateY(-50%);
    border-radius: 1.5333333333rem;
    width: 161px;
    z-index:9
}

.form-stripe-element {
    .DemoPickerWrapper {
        padding: 0 12px;
        font-family: "Source Code Pro", monospace;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        background: white;
        margin: 24px 0 48px;
        width: 100%;
      }
      
      .DemoPicker {
        font-size: 18px;
        border-radius: 3px;
        background-color: white;
        height: 48px;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        border: 0;
        width: 100%;
        color: #6772e5;
        outline: none;
        background: transparent;
      
        -webkit-appearance: none;
      }
      
      .DemoWrapper {
        margin: 0 auto;
        max-width: 500px;
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        height: 100vh;
      }
      
      .Demo {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 40%;
      }
      
      label {
        color: #6b7c93;
        font-weight: 300;
        letter-spacing: 0.025em;
      }
      
      button {
        white-space: nowrap;
        border: 0;
        outline: 0;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        color: #fff;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        background-color: $pumpkin-skin;
        text-decoration: none;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
        margin-top: 10px;
      }
      
      button:hover {
        color: #fff;
        cursor: pointer;
        background-color: $yellow;
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
      }
      
      input,
      .StripeElement {
        display: block;
        margin: 10px 0 20px 0;
        max-width: 500px;
        padding: 10px 14px;
        font-size: 1em;
        font-family: "Source Code Pro", monospace;
        box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
          rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
        border: 0;
        outline: 0;
        border-radius: 4px;
        background: white;
      }
      
      input::placeholder {
        color: #aab7c4;
      }
      
      input:focus,
      .StripeElement--focus {
        box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
          rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
      }
      
      .StripeElement.IdealBankElement,
      .StripeElement.FpxBankElement,
      .StripeElement.PaymentRequestButton {
        padding: 0;
      }
      
      .StripeElement.PaymentRequestButton {
        height: 40px;
      }
}

.stripe-modal-content {
    .modal-content {
        background-color: #f6f9fc !important;
    }
}