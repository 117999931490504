$blue: #556ee6;
$white: #FFFFFF;
$yellow-sea: #fbb304;
$totem-pole: #94240c;
$pumpkin-skin: #c4600c;

.emp-profile{
    padding: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 0.5rem;
    background: $white;
    border: 1px solid #000000;
}
.profile-img{
    text-align: center;
}
/* .profile-img img{
    width: 55%;
    height: 100%;
} */

.profile-img_container {
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
    .profile-img_container {
        justify-content: center;
    } 
}
.profile-img .file {
    position: relative;
    overflow: hidden;
    margin-top: 5px;
    width: 55%;
    border: none;
    border-radius: 1.5rem;
    font-size: 15px;
    cursor: pointer;
    background: $pumpkin-skin;
    color: $white;
}
.profile-img .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
}
.profile-edit-btn{
    border: none;
    border-radius: 1.5rem;
    width: 70%;
    padding: 2%;
    font-weight: 600;
    color: #6c757d;
    cursor: pointer;
}

.profile-connect-btn{
    border: none;
    border-radius: 1.5rem;
    width: 70%;
    padding: 2%;
    font-weight: 600;
    cursor: pointer;
    background: $pumpkin-skin;
    color: $white;
}

.subscription-update-btn {
    border: none;
    border-radius: 1.5rem;
    width: 70%;
    padding: 2%;
    font-weight: 600;
    cursor: pointer;
    background: $pumpkin-skin;
    color: $white;
    text-align: center;
}

.profile-upload-btn{
    border: none;
    border-radius: 1.5rem;
    width: 70%;
    padding: 2%;
    font-weight: 600;
    cursor: pointer;
    background: $pumpkin-skin;
    color: $white;
}

.button-profile {
    display: inline-flex;
    &>button {
        margin: 6px;
    }
}

.writing-categories {
    &>span {
        margin: 2px;
    }
}

.profile-form {
    .card-title {
        font-weight: 500;
        font-size: 17px;
    }
}

.current-plan button:disabled {
    opacity: 0.3;
}