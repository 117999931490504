.input-outline {
    border:none;
    border-bottom: 1px solid #ced4da;
    padding: 5px 10px;
    outline: none;
    max-width: 100%;
    width: 200px;
    margin-left: 15px;
} 

.label-outline {
    display: inline-block;
    margin: 0;
    text-align: right;
}

.buttons-composer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    &>div {
        margin: 4px;
    }
}
.btn-composer {
    background-color: #e1d8c7;
    border: 1px solid #000000;
    color: #000000;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    
}
.buttons-reminder {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.btn-composer:hover {
  background-color: #e1d8c7;
}
.btn-reminder {
    background-color: $pumpkin-skin;
    border: 1px solid transparent;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    
}

@media only screen and (max-width: 768px) {
    .btn-composer {
        width: 100%;
    }
}

.overall-content {
    display: flex;
    .overallitem{
        border: 1px solid green;
        width: 100px;
        height: 80px;
        padding: 10px;
        border-radius: 15px;
        .overallitem__title, .overallitem__count {
            font-size: 14px;
        }

    }
    &>div {
        margin: 12px;
    }
}

.channel {
    border: 1px solid transparent;
    border-radius: 15px;
    color: #fff;
    text-transform: uppercase;
    font-size: 9px;
    padding: 3px;
    width: 200px;
    height: 19px;
    text-align: center;
}

.channel-medium {
    background-color: #a6a6f5;
}

.channel-twitter {
    background-color: #55acee;
}
.channel-facebook {
    background-color: #29cd97;
}

.row-contact {
    display: flex;
    flex-direction: column;
}

.cell-date {
    color: rgb(137, 137, 137);
    font-size: 8px;
}

.row-date {
    display: flex;
    flex-direction: column;
}

.cell-time__publication {
    color: rgb(137, 137, 137);
    font-size: 8px;
}

.row-activity {
    display: flex;
    flex-direction: column;
}

.cell-timeline {
    color: rgb(137, 137, 137);
    font-size: 8px;
}

.social-icon {
    i {
        font-size: 15px;
        margin-right: 6px;
    }
}


.alert-empty-message {
    display: flex;
    justify-content: center;
    align-items: center;
}

.recharts-default-legend {
    li{
        font-size: 0.9rem;
        text-transform: capitalize;
    }
}

.recharts-responsive-container {
    tspan {
        font-size: 0.8rem;
        text-transform: capitalize;
    }
}

.recharts-tooltip-label {
    font-size: 1.2rem;
    text-transform: capitalize;
}

.recharts-tooltip-item-list {
    font-size: 1rem;
    text-transform: capitalize;
    
}

.recharts-default-legend {
    margin-top: 10px;
}

.angry {
  border: solid 1px #939908;
}

.fear {
    border: solid 1px #7750da;
}

.happy {
    border: solid 1px #e18369;
}

.sad {
    border: solid 1px #159196;
}

.surprise {
    border: solid 1px  #57605f;
}

.color-angry {
    color: #939908;
  }
  .color-fear {
      color: #7750da;
  }
  .color-happy {
      color: #e18369;
  }
  .scolor-ad {
      color: #159196;
  }
  .color-surprise {
      color: #57605f;
  }

.foreign_reader_ease_score {
    border: solid 1px #939908;
}
.readability_score {
    border: solid 1px #7750da;
}
.reading_ease  {
    border: solid 1px #e18369;
}
.reading_time {
    border: solid 1px #159196;
}

.color-foreign_reader_ease_score {
    color: #939908;
}
.color-readability_score {
    color: #7750da;
}
.color-reading_ease  {
 color: #e18369;
}
.color-reading_time {
 color: #159196;
}

/* */
.char_count {
    border: solid 1px #939908;
}
.char_count_without_space {
    border: solid 1px #7750da;
}
.syllable_count  {
    border: solid 1px #e18369;
}
.word_count {
    border: solid 1px #159196;
}

.sentence_count {
    border: solid 1px #7750da;
}

.color-char_count {
    color: #939908;
}
.color-char_count_without_space {
    color: #7750da;
}
.color-syllable_count {
 color: #e18369;
}
.color-word_count {
 color: #159196;
}
.color-sentence_count {
    color: #7750da;
}


// toggle styling
.slideToggle{
    display: flex;
    justify-content: center;
    margin: 50px 0;
    i {
      margin: 0 15px;
    }
  }
  .form-switch {
    align-items: center;
    display: flex;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .form-switch i {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 30px;
    border: 1px solid #c4600c;
    border-radius: 15px;
    transition: all 0.3s linear;
  }
  .form-switch i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 40px;
    height: 22px;
    background-color: #c4600c;
    border-radius: 15px;
    transform: translate3d(4px, 3px, 0);
    transition: all 0.2s ease-in-out;
  }
  
  .form-switch input { display: none; }
  
  // .form-switch input:checked + i { background-color: #0274D6; }
  
  .form-switch input:checked + i::after { transform: translate3d(54px, 3px, 0); }

  .noData-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    flex-direction: column;

    .noData-image {
        max-width: 150px;
        width: 100%;
    }
    
  }

  .noData-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350;
    flex-direction: column;

    .noData-chart {
        max-width: 150px;
        width: 100%;
    }
    
  }

  .MuiCardContent-root {
    padding: 0px !important;
  }

  .timeline-event {
    height: 400px;
    overflow-y: auto;
    padding: 0px !important;
    margin: 0px !important;

    i {
        font-size: 18px;
    }
  }

  .not-allow ul li a {
    pointer-events: none;
  }

  .stripe-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .myEditor {
    height: 400px; 
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  .paraphraseSuggestions {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 10;
  }
// Editor


.ceditor {
    display: flex;
    width: 100%;
    height: 70vh; 
    flex-direction: column;
    font-size: 14px;
    //align-items: center;
  }
  
  .editor-container {
    position: relative;
    flex-grow: 1;
  }
  
  .toolbarClassName {

    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 !important; 
    margin: 0 !important;
    display: none !important;
    justify-content: center !important;
    border: none !important;
  }
  
  .wrapperClassName {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .editorClassName {
    padding-bottom: 48px; /* Add padding-bottom to the editor equivalent to the toolbar height */
    flex-grow: 1;
    overflow: auto; /* Add this to allow scrolling within the editor */
  }

  .editorClassName .DraftEditor-root {
    padding-top: 10px
  }

  .ceditor .editor-container {
    position: relative;
  }
  
 /*  .overlay {
    display: flex;
    justify-content: center; // To center horizontally
    height: 100%; // To fill the container vertically
    font-size: 1.5em; // To enlarge the text, adjust as needed
    color: gray;
    opacity: 0.5;
  } */
  .overlay {
    display: flex;
    justify-content: center; // To center horizontally
    position: absolute; // Added this line
    font-size: 1.5em; // To enlarge the text, adjust as needed
    color: gray;
    opacity: 0.5;
    top: 10px; // Added this line, adjust as needed
    left: 0px;
    right: 0px;
    //bottom: 50px; // Added this line, adjust as needed
  }
  
  
  .upload-label {
    text-decoration: none;
    cursor: pointer;
    text-decoration: underline;
    //color: blue; // or whatever color you want
  }

  .title-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .document-title {
    margin-bottom: 40px;
    font-size: 1.3em;
    //width: 20%;
    border: none;
    text-align: left;
    outline: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .document-title::placeholder {
    font-weight: bold;
    opacity: 0.8;
    font-size: 1em;
  }



  //collapse sidebar composer
  .collapse-sidebar {
    position: relative;
    display: flex;
    //align-content: center;
    flex-direction: column;
  }
.sidebar-collapse-open {
    width: 400px;
    transition: width 0.5s ease;
    overflow: hidden;
    padding: 50px;
}

.sidebar-collapse-close {
    width: 0px;
    padding: 0px;
    transition: width 0.5s ease;
    overflow: hidden;
}

.toggle-button {
  position: absolute;
  right: 50%;
  left: 50%;
  top: 2em;
  width: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
}

.sidebar-card__container {
  height: calc(4 * 180px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.sidebar-loading__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.sidebar-card__section::-webkit-scrollbar {
  display: none;
}

.sidebar-card__section {
  -ms-overflow-style: none; 
  scrollbar-width: none;
  overflow: hidden;
}

// card details
.score-card {
  cursor: pointer;
  transition: border 0.3s ease-in-out;
}

.score-card .progress {
    height: 5px; 
    width: 100%;
  }
  
.score-card:hover {
 border-color: $yellow;
}

.score-card.active {
  border-color: $pumpkin-skin; /* Change this color to your liking */
  border-width: 2px;
}


.card__overall {
    display: inline-block;
    width: 200px; 
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
    text-align: center;
  }
  
  .card__overall:nth-child(odd) {
    border: 1px solid #f00; /* red border for odd numbered cards */
  }
  
  .card__overall:nth-child(even) {
    border: 1px solid #00f; /* blue border for even numbered cards */
  }
  

  .score-details__card {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .my-modal .modal-dialog {
    max-width: fit-content;
  }


  .lex-diversity__card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .gauge-chart {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 300px;
    }
  }

  .lex-diversity__chart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
.border-primary {
  --bs-border-opacity: 1;
  border-color: #c4600c !important;
}