//
// _header.scss
//
#page-topbar-regular {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: $header-bg;
  box-shadow: $box-shadow;
  @media (max-width: 991.98px) {
    left: 0;
  }
}
#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: $sidebar-width;
  z-index: 1002;
  background-color: $header-bg;
  box-shadow: $box-shadow;
  @media (max-width: 991.98px) {
    left: 0;
  }
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: $header-height;
  padding: 0 calc(#{$grid-gutter-width} / 2) 0 0
    /*rtl: 0 0 0 calc(#{$grid-gutter-width} / 2) */;

  .dropdown {
    &.show {
      &.header-item {
        background-color: $gray-100;
      }
    }
    .dropdown-menu {
      margin-top: 0;
    }
  }
}

.navbar-brand-box {
  padding: 0 1.5rem;
  text-align: center;
  width: $navbar-brand-box-width;
}

.logo {
  line-height: 60px;

  .logo-sm {
    display: none;
    margin-left: 16px;
  }
}

.logo-light {
  display: none;
}

/* Search */

.app-search {
  display: none !important;
  padding: calc(#{$header-height - 38px} / 2) 0;

  .form-control {
    border: none;
    height: 38px;
    padding-left: 40px;
    padding-right: 20px;
    background-color: $topbar-search-bg;
    box-shadow: none;
    border-radius: 30px;
  }
  span {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 0;
    color: $gray-600;
  }
}

// Mega menu

.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;
    a {
      color: $dropdown-color;
    }
  }
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: inline-block;
    }
  }
}

.page-content {
  padding: #{$grid-gutter-width}
    calc(#{$grid-gutter-width} / 2) $footer-height
    calc(#{$grid-gutter-width} / 2);
}

.header-item {
  height: $header-height;
  box-shadow: none !important;
  color: $header-item-color;
  border: 0;
  border-radius: 0px;
  position: relative;

  &:hover {
    color: $header-item-color;
  }
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: $gray-300;
  padding: 3px;
}

.noti-icon {
  i {
    font-size: 22px;
    color: $header-item-color;
  }

  .badge {
    position: absolute;
    top: 12px;
    right: 4px;
  }
}

.notification-item {
  .media {
    padding: 0.75rem 1rem;

    &:hover {
      background-color: $gray-300;
    }
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: $gray-600;

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    border-color: $border-color;
  }
}

// Full Screen
.fullscreen-enable {
  [data-toggle="fullscreen"] {
    .bx-fullscreen::before {
      content: "\ea3f";
    }
  }
}

body[data-topbar="dark"] {
  #page-topbar {
    background-color: $header-dark-bg;
  }
  .navbar-header {
    .dropdown .show {
      &.header-item {
        background-color: rgba($white, 0.05);
      }
    }

    .waves-effect .waves-ripple {
      background: rgba($white, 0.4);
    }
  }

  .header-item {
    color: $header-dark-item-color;

    &:hover {
      color: $header-dark-item-color;
    }
  }

  .header-profile-user {
    background-color: rgba($white, 0.25);
  }

  .noti-icon {
    i {
      color: $header-dark-item-color;
    }
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: block;
  }

  .app-search {
    .form-control {
      background-color: rgba($topbar-search-bg, 0.07);
      color: $white;
    }
    span,
    input.form-control::-webkit-input-placeholder {
      color: rgba($white, 0.5);
    }
  }
}

body[data-sidebar="dark"] {
  .navbar-brand-box {
    background: $sidebar-dark-bg;
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: block;
  }
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}

body[data-layout="horizontal"] {
  .navbar-brand-box {
    width: auto;
  }
  .page-content {
    margin-top: $header-height;
    padding: calc(#{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2)
      $footer-height calc(#{$grid-gutter-width} / 2);
  }
}

@media (max-width: 992px) {
  body[data-layout="horizontal"] {
    .page-content {
      margin-top: 15px;
    }
  }
}

#alert-bar {
  padding-bottom: 80px;
}
#hellobar-bar {
  font-family: "Open Sans", sans-serif;
  /* width: calc(100% - 250px); */
  width: 100%;
  margin: 0;
  height: 30px;
  display: table;
  font-size: 17px;
  font-weight: 400;
  padding: .33em .5em;
  -webkit-font-smoothing: antialiased;
  color: #5c5e60;
  position: fixed;
  background-color: white;
  box-shadow: 0 1px 3px 2px rgba(0,0,0,0.15);
  position: absolute;
  top: 62px;
  right:0;
}
#hellobar-bar.regular {
  height: 30px;
  font-size: 14px;
  padding: .2em .5em;
}
.hb-content-wrapper {
  text-align: center;
  text-align: center;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  
}
.hb-content-wrapper p {
  margin-top: 0;
  margin-bottom: 0;
}
.hb-text-wrapper {
  margin-right: .67em;
  display: inline-block;
  line-height: 1.3;
}
.hb-text-wrapper .hb-headline-text {
  font-size: 1em;
  display: inline-block;
  vertical-align: middle;
}
#hellobar-bar .hb-cta {
  display: inline-block;
  vertical-align: middle;
  margin: 5px 0;
  color: #ffffff;
  background-color: #c4600c;
  border-color: #c4600c;
  border-radius: 30px;
}
.hb-cta-button {
  opacity: 1;
  color: #fff;
  display: block;
  cursor: pointer;
  line-height: 1.5;
  max-width: 22.5em;
  text-align: center;
  position: relative;
  border-radius: 3px;
  white-space: nowrap;
  margin: 1.75em auto 0;
  text-decoration: none;
  padding: 0;
  overflow: hidden;
}
.hb-cta-button .hb-text-holder {
  border-radius: inherit;
  padding: 5px 15px;
}
.hb-close-wrapper {
  display: table-cell;
  width: 1.6em;
}
.hb-close-wrapper .icon-close {
  font-size: 14px;
  top: 15px;
  right: 25px;
  width: 15px;
  height: 15px;
  opacity: .3;
  color: #000;
  cursor: pointer;
  position: absolute;
  text-align: center;
  line-height: 15px;
  z-index: 1000;
  text-decoration: none;
}